.scroll {
    width: 200px;
    height: 400px;
    overflow: auto;
 }
 .scroll::-webkit-scrollbar {
     width: 8px;
 }
 
 .scroll::-webkit-scrollbar-track {
     border-radius: 10px;
 }
 
 .scroll::-webkit-scrollbar-thumb {
     border-radius: 10px;
 }