.custom_chip_style {
    border-radius: 50px;
    margin-right: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: default !important;
    padding-top: 3px;
    padding-bottom: 2px;
    padding-left: 14px;
    padding-right: 14px;
    height: 26px;
}
