.isMobile{
  display: none;
}

body{
  overflow: auto;
}

@media only screen and (max-width: 600px) {
  .istablet {
    display: none;
  }
  .isMobile{
    display: block;
    height: 100vh;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100vw;
    text-align: center;
  }
}

.table {
  width: 100%;
}

.app-content-full-view{
  margin-inline-start: 70px;
}

.map-view{
  /* position: relative; */
}

.hide {
  display:none;
} 


.view_mode_hide {
  display:none !important;
} 

.view_mode_show {
  display:block !important;
} 

.map_statistics{
  position: absolute;
  /* top:35px; */
  z-index: 1;
  padding: 20px;
  width: 100%;
  overflow: auto;
}

.map-toggle-btn{

  /* position: absolute; */
  top:5px;
  right: 65px;
  z-index: 1;
  /* width: 220px !important; */
}

.summaryPopUp{
width: 17rem;
border-radius: 8px;
/* border: 2px soild navy; */
}

.summaryPopUp .header{
  border-radius: 8px 8px 0px 0px;
}

.summaryPopUp table{
  margin-top: 3rem;
}

.summaryPopUp td{
  padding: 8px;
}

.progress-view{
  margin-top: 14px;
  margin-left: 17px;
}

.gm-style .gm-style-iw-c{
  /* border:2px solid navy; */
  border-radius: 5px;
  padding: 5px;
  border-radius: 10px;
}

.gm-style-mtc {
  display: none !important;
}

.gm-ui-hover-effect>span{
  background-color: #fff;
    width: 20px !important;
    height: 20px !important;
    margin: 0px !important;
    margin-top: 1rem !important;
}

.summaryPopUp .header{
  /* background-color: navy; */
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  /* color: white; */
  position: absolute;
  width: 100%;
  left: 0;
  top:0;
}

.percent {
  position: relative;
}

.progress-view svg {
  position: relative;
  width: 150px; /* Updated width */
  height: 150px; /* Updated height */
  transform: rotate(-90deg);
}

.progress-view svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #f0f0f0;
  stroke-width: 7; /* Adjusted stroke width */
  stroke-linecap: round;
}

.number {
  position: absolute;
  top: 57%;
  left: 40%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number .value {
  font-weight: 500;
  font-size: 1.3rem; /* Adjusted font size */
}

.number .value span {
  font-size: 1.2rem; /* Adjusted font size */
}

.title{
  font-size: 0.7rem;
  font-weight: 400;
}

.progress-one svg circle:last-of-type {
  /* stroke: #2ecc71; */
  stroke: navy;
}
.progress-two svg circle:last-of-type {
  stroke: #2ecc71;
}

.status-content{
  background: #D0FFBC !important;
}

.map-filters{
  background-color: white;
  position: absolute;
  display: flex;
  top: 0px;
  padding: 28px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-wrap: wrap;
  gap: 10px;
  /* padding: 10px; */
  z-index: 15;
}

.routing-details-filters{
  position: absolute;
  background-color: white;
  width: 300px;
  height: 100px;
  top: 100px;
  right: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.clear-map-filters{
  position: absolute;
  top: 20px;
  right: 230px;
  z-index: 1;
}

.clear-map-filters-save{
  position: absolute;
  top: 20px;
  right: 325px;
  z-index: 1;
}

.page{
  display: flex;
  justify-content: unset;
  height: 100vh;
  /* background: red; */
}

.outer-container{
    overflow: auto;
    overflow-x: hidden;
    /* background-color: white; */
    position: absolute;
    height: auto;
    width: 100%;
}

/* .card-body{
  padding: 0px;
}

.card-header{
  padding: 12px;
} */


.gm-style-iw{
  top:-2.5rem !important;
} 


.gm-style .gm-style-iw-tc::after{
  top:-2.5rem;
}

.info_chip {
  padding: 0px 3px;
  margin: 0px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.85rem;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
}

.data-not-found {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  color: gray;
  padding-top: 13%;
}