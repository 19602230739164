.border-bottom{
     border-bottom: none !important;
}

.bold-label{
font-weight: bolder;
}

.table-cell{
      border-bottom: none !important;
      padding-bottom: 8px !important;
                                                        
}

.nearme-icon-clicked {
  border: 2px solid black; 
  color: black ;
  border-radius: 50%; 
  animation: colorChange 0.9s forwards; 
}

@keyframes colorChange {
  0% {
    color: red;
    border-color: red; 
  }
  100% {
      color: black;
    border-color: black;
  }
}
