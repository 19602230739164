.info_chip {
    padding: 0px 3px;
    margin: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.85rem;
    min-width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
}

.info_chip2 {
    margin: 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.85rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    height: 20px !important;
    // @media (min-width: 768px) {
    //     background-color: blue !important;
    // }

    @media (min-width: 950px) {
        // background-color: green !important;
        height: 60px !important;
    }

    @media (min-width: 1550px) {
        // background-color: blue !important;
        height: 30px !important;
        width: 160px !important;
    }

    @media (min-width: 990px) and (max-width: 1580px) {
        // background-color: black;

        height: 90px !important;
    }
}

.info_chip_container {
    @media (min-width: 1550px) {
        // background-color: blue !important;
        height: 90px !important;
        width: 330px !important;
        flex-wrap: wrap !important;
        margin: auto !important;
        gap: 5px !important;
    }
}

.icon_container {
    display: flex;
    gap: 10px;

    margin-left: 15px;

    @media (min-width: 990px) and (max-width: 1580px) {
        flex-direction: column;
        width: 25px;
        gap: 10px;
        // background-color: black;
        height: 70px;
        margin-left: 0px;
    }

    @media (max-width: 990px) {
        // background-color: black;
        width: 80px;
    }

    @media (min-width: 1580px) {
        width: 80px;
    }
}

.icon {
    height: 28px;

    @media (min-width: 990px) and (max-width: 1580px) {
        height: 26px;
    }
}

.icon_font {
    font-size: 14px;
    align-self: center;

    @media (min-width: 990px) and (max-width: 1580px) {
        font-size: 13.5px;
    }
}
