.responsive-btn {
  width: 121px;
  display: block !important;
}

.responsive-btn-icon {
  display: none !important;
}

@media screen and (max-width: 1000px) {
  .responsive-btn {
    margin-right: 44px;
    /* display: none !important; */
  }

  .responsive-btn-icon {
    display: block !important;
  }
}


.range-container {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
    padding-left: 8px;
   
    margin-left: -5px;
    height: 36px;
    
}
.range-value {
  font-weight: normal;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.5);
}

.line {
    width: 14px;
    height: 1px; 
     background-color:  rgba(0, 0, 0, 0.5);
    margin: 0 2px;
}
