.heading {
}

.subheading {
}

.contact-header{
    margin-top:20px;
    background-size:100% 100%;
    background-image:url('../../assets/images/basic/mail_header.png');
    height:400px;
    object-fit:fit;
    background-repeat:no-repeat;
}
    